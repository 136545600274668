.table-body-center tr, .table-body-center td {
  text-align: center;
}
.table-head-center th {
  text-align: center;
}
.sidebar-logo {
  font-weight: 900;
  color: azure;
}
.input-error {
  border: 1px solid red !important;
  border-radius: 0.35rem !important;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiInputBase-root {
  height: 38px;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 0.35rem !important;
}
.no-wrap {
  white-space: nowrap;
}
.table th, .table td {
  padding: 8px;
}
.tbl-border > :not(caption) > * {
  border-width: 1px;
}
.fs-6 {
  font-size: 15px !important;
}
