.react-time-picker__inputGroup__divider {
  padding: 0 5px !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
.react-time-picker__inputGroup__input {
  font-size: 1rem !important;
}
.side-menu-logo {
  background: white;
  border-right: 1px solid #d8dbe0;
}

/* START --> Hide date picker's time selection scroll bar */
.MuiMultiSectionDigitalClock-root ul {
  overflow: hidden; /* Hide both scrollbars */
}
.MuiMultiSectionDigitalClock-root ul {
  overflow: -moz-scrollbars-none; /* For older versions of Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For modern versions of Firefox */
}
.MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
/* END --> Hide date picker's time selection scroll bar */
