// Here you can add other styles

.table th {
  padding: 5px;
}
.table td {
  padding: 5px;
}
.signIn {
  margin-left: 180px;
}
.forgotPassword {
  margin-left: 180px;
}
.error {
  color: red;
}
//   #userTable {
//     font-family: Arial, Helvetica, sans-serif;
//     border-collapse: collapse;
//     width: 100%;
//   }

//   #userTable td, #userTable th {
//     border: 1px solid #ddd;
//     padding: 8px;
//   }

//   #userTable tr:nth-child(even){background-color: #f2f2f2;}

//   #userTable tr:hover {background-color: #ddd;}

//   #userTable th {
//     padding-top: 12px;
//     padding-bottom: 12px;
//     text-align: left;
//     background-color: #04AA6D;
//     color: white;
//   }
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: #4f5d73;
  color: white;
}
.edit {
  background-color: #4f5d73;
}

.on-timer {
  font-size: 25px;
  font-weight: 700;
  border: 1px solid yellow;
  width: 110px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #50b954 0%, #50b954 51%, #50b954 100%);
  color: white;
}

.off-timer {
  font-size: 25px;
  font-weight: 700;
  border: 1px solid yellow;
  width: 110px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #bb0232 0%, #bb0232 51%, #bb0232 100%);
  color: white;
}
